import React,{useState,useEffect,useRef} from 'react';
import styles from './index.module.scss';
import {reservationMeeting,modifyMeeting,getMeetingDetaile} from './api'

import { Input,Switch,Popup,CheckList,Radio, Space,Toast } from 'antd-mobile'
import {Videocamera,Right} from '@icon-park/react'
import DatePickerCompontent from '../../components/datePickerCompontent/index'
import MeetingToRemindComponent from '../../components/meetingToRemindComponent/index'
import CollapseComponent from '../../components/collapseComponent/index'
import {getNewDate,getDelayMinutesDate,getCurrDate,getDelayTheTime} from '../../utils/time'
import {randomNumberGeneration} from '../../utils/tool'
import {weixinJSBridge} from '../../wx/weixinJSBridge'
import Cookies from 'js-cookie'
import localStorage from '../../utils/localStorage'
import {useRootStore} from '../../store/index'
import moment from 'moment';
function CreateMeeting(props) {

  const {type,meetingType,meetid} = props.location.state || {}

  //会议主题input value
  const [theThemeValue, setTheThemeValue] = useState('')

  //会议开始时间
  const [startDate, setStartDate] = useState({...getNewDate()[0]})

  //会议结束时间
  const [endDate, setEndDate] = useState({...getNewDate()[1]})

  //定时器
  let timer = useRef();

  //会议录制value值
  const [theRecordingValue, setTheRecordingValue] = useState('localRecording')

  //会议类型alue值
  const [mingType, setMingType] = useState('zoom')

  //会议录制开关状态
  const [theRecordingState, setTheRecordingState] = useState(false)

  //会议密码input value值
  const [meetingPassword, setMeetingPassword] = useState(randomNumberGeneration())

  //会议密码开关状态
  const [meetingPasswordState, setMeetingPasswordState] = useState(true)

  //主持人码开关
  const [is_need_host_key, setIs_need_host_key] = useState(false)

  //会议密码input框
  const passwordInput = useRef(); 

  //会议提醒弹窗显隐状态
  const [remindVisible, setRemindVisible] = useState(false)

  //会议提醒文案
  const [remindTheTime, setRemindTheTime] = useState('5分钟前')

  //入会静音开关状态
  const [muteState, setMuteState] = useState(true)

  //等候室开关状态
  const [theWaitingRoomState, setTheWaitingRoomState] = useState(false)

  //屏幕水印开关状态
  const [watermarkState, setWatermarkState] = useState(false)

  //会议类型单选框Disabled状态
  const [meetingdisabled, setMeetingdisabled] = useState({
    zoomDisabled:false,
    txDisabled:false
  })

  //mobx状态
  const {authorization} = useRootStore()

  //屏幕水印开关状态
  const [saveOrModify, setSaveOrModify] = useState('保存')

  //会议号
  const [meeting_no, setMeeting_no] = useState('')

  const [id, setId] = useState('')

  //用户id
  const [userid, setUserid] = useState('')

  /**
   * @author: wangyanhui
   * @description: 页面第一次加载完成
   * @return {*}
   */
  useEffect(()=>{
    init()
  },[])

  /**
   * @author: wangyanhui
   * @description:开始时间或者结束时间发生变化时执行useEffect里的函数
   * @return {*}
   */
  useEffect(()=>{
    if(props.location.state === undefined){ //如果是预定会议才会开启定时器
      initSystimeInterval();
    }
    return ()=>{
      timer.current && clearInterval(timer.current)
    }
  },[startDate,endDate])

  /**
   * @author: wangyanhui
   * @description: 初始化函数
   * @return {*}
   */
  function init(){
    const {meetid} = props.location.state || {}
    //如果是从详情跳转过来的并且有meetid 
    if(meetid){
      let params = {
        pool_meeting_id:meetid
      }
      getMeetingDetaileData(params)
    }
  }


    /**
   * @author: wangyanhui
   * @description: 详情页面请求接口
   * @return {*}
   */  
    async function getMeetingDetaileData(params){
      let data = await getMeetingDetaile(params)
      if(data['status'] != 0){
        // Toast.show({
        //   icon: 'fail',
        //   content: data['message']
        // })
        console.log(data)
        return;
      }

      if(data['status'] == 0 &&
         data['pool_resp'] && 
         data['pool_resp']['content'] &&
         data['pool_resp']['content']['mt_meeting_info'] && 
         data['pool_resp']['content']['mt_meeting_info']['data'] && 
         data['pool_resp']['content']['mt_meeting_info']['data'].length !=0 
      ){
        let meetingsDetaile = data['pool_resp']['content']['mt_meeting_info']['data'][0];
        let {meettitle,starttime,endtime,remindertime,meettype,watermark,pass,waitingroom,code,userid,record_type,id,hostkey} = meetingsDetaile
        console.log(meetingsDetaile)
        setTheThemeValue(meettitle)
        setStartDate({...getNewDate(moment(starttime))[0]})
        setEndDate({...getNewDate(moment(endtime))[0]})
        setMeetingdisabledFun(meettype)
        setMeetingPassword(pass)
        setSaveOrModify('修改')
        setRemindTheTime(setrTemindertime(remindertime,'修改'))
        // setTheWaitingRoomState(strConversionBoolean(waitingroom)) //等候室状态
        setTheWaitingRoomState(false) //等候室状态
        setWatermarkState(strConversionBoolean(watermark))        //屏幕水印状态
        setMeeting_no(code)
        setId(id)
        setUserid(userid)
        setTheRecordingState((val)=>{
          
          val = record_type === 'LOCAL'
          return val;
        })
        setIs_need_host_key(()=>{
          return !!hostkey
        })      //主持人码开关
      }
    }

  function strConversionBoolean(str){
    if(str == "false"){
      return false
    }else if(str == "true"){
      return true
    }
  }


  /**
   * @author: wangyanhui
   * @description: 会议类型单选框disabled状态处理逻辑
   * @return {*}
   */
  function setMeetingdisabledFun(typeStr){
    setMingType(typeStr)
    if(typeStr == 'zoom'){
      setMeetingdisabled({
        zoomDisabled:false,
        txDisabled:true
      })
    }else if(typeStr == 'tcmeeting'){
        setMeetingdisabled({
        zoomDisabled:true,
        txDisabled:false
      })
    }
  }

  /**
   * @author: wangyanhui
   * @description: 开启定时器时时获取当前系统时间和开始时间对比
   * @return {*}
   */
  function initSystimeInterval(){
    timer.current = setInterval(()=>{
      //当系统时间比页面显示的开始时间大于一分钟时 开始时间和结束时间往后推迟
      if(new Date() > startDate.dateObj){
        let [start,,poor] = getNewDate();
        if(poor!==0){
          setStartDate({
            ...start
          });
          setEndDate({
            ...getDelayMinutesDate(endDate,5)
          })
        }
      }
    },1000)
  }

  

  /**
   * @author: wangyanhui
   * @description: 开始日期校验方法
   * @return {*}
   */
  function dateCheckStart(val){
    let checkObj = {
      isCheckd:false,
      prompt:''
    }
    let num = endDate.dateObj - val.dateObj;
    if(val.dateObj - getCurrDate().dateObj < 0){
      checkObj.isCheckd = true;
      checkObj.prompt = '开始时间不能小于当前时间';
    }else if(num <= 0){ //如果开始时间大于结束时间 结束时间往后推一个小时
      checkObj.isCheckd = false;
      checkObj.prompt = ''
      setEndDate(getDelayTheTime(val.dateObj,1,'hours'))
    }else{
      checkObj.isCheckd = false;
      checkObj.prompt = ''
    }
    return checkObj;
  }

  /**
   * @author: wangyanhui
   * @description: 结束日期校验方法
   * @return {*}
   */
   function dateCheckEnd(val){
    let checkObj = {
      isCheckd:false,
      prompt:''
    }

    let num = val.dateObj - startDate.dateObj;
    if(num === 0){
      checkObj.isCheckd = true;
      checkObj.prompt = '开始时间不能等于结束时间';
    }else if(num < 0){
      checkObj.isCheckd = true;
      checkObj.prompt = '结束时间不能小于开始时间';
    }else{
      checkObj.isCheckd = false;
      checkObj.prompt = '';
    }
    return checkObj;
  }

  /**
   * @author: wangyanhui
   * @description: 保存方法
   * @return {*}
   */
   async function submit(){
    const {meetid} = props.location.state || {}
    if(isCheck()){ //如果校验通过

      if(meetid){  //如果修改会议
        let data = {
          "access_token": Cookies.get('access_token'),
          "state": "modify",
          "host_wxp_openid": localStorage.get('openid'),
          "host_wx_nickname": localStorage.get('nickname'), 
          "topic": theThemeValue?theThemeValue:`${localStorage.get('full_name')}的会议`, //会议主题
          "start_date": `${startDate.year}-${startDate.month}-${startDate.date}`,
          "end_date": `${endDate.year}-${endDate.month}-${endDate.date}`,
          "start_time": `${startDate.hour}:${startDate.minute}`,
          "end_time": `${endDate.hour}:${endDate.minute}`,
          "duration": (endDate.dateObj - startDate.dateObj) / 1000 / 60, //会议持续时间 分钟
          // "waiting_room_flg": "N",
          "meeting_type": mingType,
          "password":  meetingPassword,
          "host_password": "",
          "timezone": 8,
          // "waitingroom":String(theWaitingRoomState),
          "waitingroom":String(false),
          "remindertime":setrTemindertime(remindTheTime,'保存'),
          "watermark":String(watermarkState),
          "mute_upon_entry": "true",
          "pool_meeting_id": meetid,
          "meeting_no": meeting_no,
          "pool_user_id": userid,
          "platform_meeting_id":mingType == 'zoom' ? meeting_no : id,
          "record_type":theRecordingState?'LOCAL':'NONE',
          "hostkey":String(is_need_host_key)
        }
        console.log(data,'修改的入参')
        modifyMeeting(data).then(({status,message})=>{
          if(status == 0){
            Toast.show({
              icon: 'success',
              content: message,
            })
            setTimeout(()=>{
              weixinJSBridge('closeWindow')
            },1000)
          }else{
            // Toast.show({
            //   icon: 'fail',
            //   content: message,
            // })
            console.log(message)
            // setTimeout(()=>{
            //   weixinJSBridge('closeWindow')
            // },1000)
          }
        }).catch((err)=>{
          console.log(err)
        })

      }else{       //如果是预定会议
        let data = {
          "access_token": Cookies.get('access_token'),
          "state": authorization.code,
          "host_wxp_openid": localStorage.get('openid'),
          "host_wx_nickname": localStorage.get('nickname'), 
          "topic": theThemeValue?theThemeValue:`${localStorage.get('full_name')}的会议`, //会议主题
          "start_date": `${startDate.year}-${startDate.month}-${startDate.date}`,
          "end_date": `${endDate.year}-${endDate.month}-${endDate.date}`,
          "start_time": `${startDate.hour}:${startDate.minute}`,
          "end_time": `${endDate.hour}:${endDate.minute}`,
          "duration": (endDate.dateObj - startDate.dateObj) / 1000 / 60, //会议持续时间 分钟
          // "waiting_room_flg": "N",
          "meeting_type": mingType,
          "password":  meetingPassword,
          "host_password": "",
          "timezone": 8,
          // "waitingroom":String(theWaitingRoomState),
          "waitingroom":String(false),
          "remindertime":setrTemindertime(remindTheTime,'保存'),
          "watermark":String(watermarkState),
          "mute_upon_entry": "true",
          "record_type":theRecordingState?'LOCAL':'NONE',
          "hostkey":String(is_need_host_key)
        }
        console.log(data,'保存的入参')

        Cookies.get('access_token')
        //掉保存接口
        reservationMeeting(data,{
          closeWindow:true
        }).then(({status,message})=>{
          if(status == 0){
            Toast.show({
              icon: 'success',
              content: message,
            })
            weixinJSBridge('closeWindow')
          }else{
            // Toast.show({
            //   icon: 'fail',
            //   content: message,
            // })
            console.log(message)
            weixinJSBridge('closeWindow')
          }
        }).catch((err)=>{
          console.log(err)
        })
        
      }
      
    }
  }

  function setrTemindertime(str,flg){
    if(!str || (str && str.length == 0) ) return 0;
    if(flg == '保存'){
      let temindertime = {
        '5分钟前':5,
        '15分钟前':15,
        '1小时前':60,
      }
      return temindertime[str]
    }else if(flg == '修改'){
      let temindertime = {
        5:'5分钟前',
        15:'15分钟前',
        60:'1小时前'
      }
      return temindertime[str]
    }
  }



  function isCheck(){
    //会议时间开始及结束时间校验
    let hours = (endDate.dateObj - startDate.dateObj) / (60 * 60 * 1000);
    if(hours > 8){
      Toast.show({
        content: '超出会议最大时常8小时,请重新选择开会时间',
        afterClose: () => {
          console.log('')
        },
      })
      return false;
    }
    
    //会议数字密码校验
    if(meetingPassword.length < 6 && meetingPasswordState){
      Toast.show({
        content: '请输入6位数字密码',
        maskClassName: 'meeting_theme_toast',
        afterClose: () => {
          console.log('')
        },
      })
      return false;
    }
    return true;
  }

  return (
    <div className={styles.root + ' base_height'}>
        <div className='theme base_margin_bottom'>
          <Input value={theThemeValue} 
          onChange={(val)=>{
            if(val.length >15){
              Toast.show({
                position:'top',
                maskClassName: 'meeting_theme_toast',
                getContainer:()=>document.querySelector('.base_margin_bottom'),
                content: '会议主题限制最多15个字符',
                afterClose: () => {
                  console.log('')
                },
              })
            }else{
              setTheThemeValue(val)
            }
          }} 
          placeholder='会议主题' clearable />
          <Videocamera theme="outline" size="30" fill="#4a90e2" strokeWidth={2}/>
        </div>
        <div className='time base_margin_bottom'>
          <span className='date-picker-box'>
            <DatePickerCompontent 
              setDate={(props)=>{
                setStartDate(props)
              }} 
              time={startDate} 
              dateCheck={dateCheckStart} 
            />
          </span>
          <Right theme="filled" size="38" fill="#9b9b9b"/>
          <span className='date-picker-box date-picker-right'>
            <DatePickerCompontent 
              setDate={(props)=>{
                setEndDate(props)
              }} 
              time={endDate} 
              dateCheck={dateCheckEnd} 
            />
          </span>
        </div>
        <div className='meeting_type base_style base_margin_bottom'>
          <span>会议类型</span>
          <div>
            {/* <span>中国标准时间-北京</span> */}
            {/* <Right theme="filled" size="18" fill="#9b9b9b"/> */}
            <Radio.Group onChange={(val)=>{ setMingType(val)}}  value={mingType}>
              <Space direction='vertical'>
                <Radio value='zoom' disabled={meetingdisabled.zoomDisabled}>Zoom</Radio>
                <Radio value='tcmeeting' disabled={meetingdisabled.txDisabled}>腾讯会议</Radio>
              </Space>
            </Radio.Group>
          </div>
        </div>
        <div className='base_style base_margin_bottom'>
          <span>时区</span>
          <div>
            <span>中国标准时间(GMT+8)</span>
            {/* <Right theme="filled" size="18" fill="#9b9b9b"/> */}
          </div>
        </div>
        {/* <div className='base_style base_margin_bottom disabled'>
          <span>地点</span>
          <div>
            <span>选择会议室</span>
            <Right theme="filled" size="18" fill="#9b9b9b"/>
          </div>
        </div> */}
        <div className='base_style base_margin_bottom' 
        onClick={()=>{
          setRemindVisible(true)
        }}>
          <span>提醒</span>
          <div>
            <span>
              <MeetingToRemindComponent
                remindTheTime={remindTheTime}
                setRemindTheTime={(val)=>{
                  setRemindTheTime(val)
                }}
                remindVisible={remindVisible}
                setMeetingVisible = {(val)=>{
                  setRemindVisible(val)
                }}
              />
            </span>
            <Right theme="filled" size="18" fill="#9b9b9b"/>
          </div>
        </div>
        {/* <div className='base_style base_margin_bottom'>
          <span>等候室</span>
          <Switch
            disabled={true}
            checked={theWaitingRoomState}
            onChange={(val)=>{setTheWaitingRoomState(val)}}
          />
        </div> */}
        <div className='base_style base_margin_bottom'>
          <span>主持人码</span>
          <Switch
            checked={is_need_host_key}
            onChange={(val)=>{setIs_need_host_key(val)}}
          />
        </div>
        <div className='the_meeting_password base_margin_bottom'>
          <CollapseComponent 
              checked={meetingPasswordState}
              checkedFun={(val)=>{
                console.log(val)
                setMeetingPasswordState(adsf)
                if(val){
                  setMeetingPassword(randomNumberGeneration())
                }
              }}
              title='会议密码'
              foucInput={()=>{
                let inputDom = passwordInput.current;
                if(inputDom.value === ''){
                  inputDom.focus();
                  window.scrollTo({top:300000,behavior: "smooth"})
                }
              }}
          >
              <input value={meetingPassword} placeholder="请输入6位数字密码" maxLength="6" ref={passwordInput}  type='tel' pattern='\d*' onChange={(event)=>{
                let inputDom = passwordInput.current;
                inputDom.value = inputDom.value.replace(/[^\d]/g,'')
                setMeetingPassword(inputDom.value)
              }}/>
          </CollapseComponent>
        </div>
        {/* <div className='the_meeting_record base_margin_bottom'>
          <CollapseComponent 
              title='会议录制'
              checked={theRecordingState}
              checkedFun={(val)=>{
                setTheRecordingState(val)
              }}
          >
                <CheckList defaultValue={[theRecordingValue]} onChange={(value)=>{
                  setTheRecordingValue(value)
                }}>
                  <CheckList.Item value='cloudToRecord'>开启云端录制</CheckList.Item>
                  <CheckList.Item value='localRecording'>开启本地录制</CheckList.Item>
                </CheckList>
          </CollapseComponent>
        </div> */}
        <div className='base_style base_margin_bottom'>
          <span>会议录制</span>
          <Switch 
            checked={theRecordingState}
            onChange={(val)=>{setTheRecordingState(val)}}
          />
        </div>
        {/* <div className='base_style base_margin_bottom'>
          <span>入会静音</span>
          <Switch 
            checked={muteState}
            onChange={(val)=>{setMuteState(val)}}
          />
        </div> */}
        <div className='base_style base_margin_bottom'>
          <span>屏幕水印</span>
          <Switch 
            checked={watermarkState}
            onChange={(val)=>{setWatermarkState(val)}}
          />
        </div>
        {/* <div className='base_style base_margin_bottom disabled'>
          <span>周期会议</span>
          <Switch defaultChecked={false} disabled/>
        </div> */}
        <div className='base_style base_margin_bottom save'>
          <span onClick={submit}>{saveOrModify}</span>
        </div>
    </div>
  );
}
export default CreateMeeting;
