import React,{useState,useEffect,useRef} from 'react';
import {connect} from 'react-redux'
import styles from './index.module.scss';
// let view_resource_url = '/Dashboard/DashboardPage.htm'
// let view_resource_url = 'https://acertest.s-connector.com/MT/Projects/Dashboard/DashboardPage.htm'
// let view_resource_url = 'https://respool.chunhon-info.com/WaterSystem0.9/Projects/Dashboard/DashboardPage.htm'
let view_resource_url = 'https://mmp.chunhon-info.com/Dashboard/DashboardPage.htm'
function Report() {
  return (
    <div className={styles.root + ' base_height'}>
        <iframe  width="100%" height="100%" src={view_resource_url}></iframe>
    </div>
  );
}
export default Report;
