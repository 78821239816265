import React, { useState, useCallback, useEffect } from 'react'
import { DatePicker,Toast } from 'antd-mobile'
import styles from './index.module.scss'
import {getDate} from '../../utils/time'

function DatePickerCompontent(props) {
    const {setDate = ()=>{},time = getDate(),dateCheck} = props;

    //时间控件显隐状态
    const [visible, setVisible] = useState(false) 
    const [selectDate,setSelectDate] =  useState(()=>{
      return time
    })

    useEffect(()=>{
      console.log(time)
    },[time])
    /**
     * @author: wangyanhui
     * @description: 时间控件年月日三列加年月日文案
     * @param {*} useCallback
     * @param {*} data
     * @return {*}
     */    
    const labelRenderer = useCallback((type, data) => {
        switch (type) {
          case 'year':
            return data + '年'
          case 'month':
            return data + '月'
          case 'day':
            return data + '日'
          default:
            return data
        }
    }, [])

    /**
     * @author: wangyanhui
     * @description: 过滤分钟那一列的每一行数据 只保留5的倍数 包含0
     * @return {*}
     */
    const dateFilter = {
      minute: (val, { date }) => {
        if(val%5!=0){
          return false;
        }
        return true;
      }
    }

    

    return (
        <div className={styles.root}>
            <span className='time_left' onClick={() => {
                  setVisible(true)
                }}>
                <p>{time.hour}:{time.minute}</p>
                <span>{time.month}月{time.date}日</span>
                <span>{time.week}</span>
            </span>
            <DatePicker
              closeOnMaskClick={true}
              value = {time.dateObj}
              visible={visible}
              onClose={() => {
                if(dateCheck){               //如果传了日期校验方法
                  let {isCheckd,prompt} = dateCheck(selectDate);  //对日期做校验
                  if(isCheckd){
                    Toast.show({
                      content: prompt,
                      afterClose: () => {
                        console.log('after')
                      },
                    })
                  }
                  setVisible(isCheckd)       //弹窗显示隐藏
                }else{                       //如果没有日期传校验方法
                  setVisible(false)          //直接隐藏弹窗
                }
              }}
              precision='minute'
              onSelect={(val)=>{
                setSelectDate(getDate(val))
              }}
              onConfirm={val => {
                if(dateCheck){               //如果传了日期校验方法
                  
                  let {isCheckd} = dateCheck(getDate(val));  //对日期做校验
                  if(!isCheckd){
                    setDate(getDate(val));   //联动上方数据
                  }
                  setVisible(isCheckd)       //弹窗显示隐藏
                }else{                       //如果没有日期传校验方法
                  setDate(getDate(val));     //联动上方数据
                  setVisible(false)          //直接隐藏弹窗
                }
              }}
              renderLabel={labelRenderer}
              filter={dateFilter}
            />
        </div>
    )
}
export default DatePickerCompontent;