import React,{useEffect} from 'react';
import styles from './index.module.scss';
import { Toast } from 'antd-mobile'

function appPage(props) {
  useEffect(()=>{
    Toast.show({
      icon: 'fail',
      content: '该功能需申请试用后，方可使用。',
    })
  },[])
  return (
    <div className={styles.root}>
        暂无权限
    </div>
  );
}
export default appPage;
