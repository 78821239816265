import React,{useState,useEffect} from 'react';
import styles from './index.module.scss';
import TheMeetingNumber from '../../components/theMeetingNumber/index'
import TheMeetingTime from '../../components/theMeetingTime/index'
import TheMeetingSplitLine from '../../components/theMeetingSplitLine/index'
import {ArrowLeft,DifferenceSet,TwoDimensionalCodeOne,CloseOne,Devices} from '@icon-park/react'
import { Dialog,Toast } from 'antd-mobile'
import {
  getMeetingDetaile,
  deleteMeeting,
  fetchQcode,
  fetchEmailBody
} from './api.js'
import {
  get_reg_auth_url
} from '../../api/reg_auth.js'
import moment from 'moment';
import {getCurrDate} from '../../utils/time'
import {weixinJSBridge} from '../../wx/weixinJSBridge'
import qs from 'querystring'
import localStorage from '../../utils/localStorage'


function MeetingDetaile(props) {
  // 接收search参数
  const {search} = props.location || {}
  const {source,meetid,full_name,openid} = qs.parse(search.slice(1))

  //会议详情
  const [mingDetaile, setMingDetaile] = useState(()=>{
    return {
      platform_meeting_id:'',
      mingType:'',
      mingTypeAndCode:{
        meettitle:'',
        code:''
      },
      time:{
        startTime:{
            hm:'',
            ymd:''
        },
        endTime:{
            hm:'',
            ymd:''
        },
        status:'',
        originalStartTime:'',
        state:'',
        duration:''
      },
      joinurl:'',
      starttime:'',
      endtime:'',
      pass:''
    }
  })

  const [dialogVisible,setDialogVisible] = useState(false)

  const [qcode_url,setQcode_url] = useState('')

  const [emailData,setEmailData] = useState('')

  const [isBeShared,setIsBeShared]= useState(false) 

  const [showSharedBox,setShowSharedBox] = useState(false)

  const [showSharedBoxTwo,setShowSharedBoxTwo] = useState(false)
  /**
   * @author: wangyanhui
   * @description: 页面第一次渲染完成
   * @return {*}
   */  
  useEffect(()=>{
    if(!localStorage.get('full_name')){
      setShowSharedBoxTwo(true) //显示模版遮住详情页面
      let qcodeParams = {
        muid:meetid,
        host:openid,
        action:'SHARE_MEETING'
      }
      fetchQcode(qcodeParams).then((qcodeData)=>{
        let {qcode_url} = qcodeData;
        qcode_url && setQcode_url(qcode_url)
      }).catch((err)=>{
        console.log(err)
      })
      
      // props.history.replace('/followPublicAccounts')
      // Toast.show({
      //   content: '请关注并申请账号',
      //   afterClose: () => {
      //     console.log('after')
      //   },
      // })
      // return;
      // get_reg_auth_url(meetid).then((res)=>{
      //   console.log(res)
      //   window.location.replace(res)
      //   // locationReplace(res)
      // })
    }else{
      setIsBeShared(!(full_name === localStorage.get('full_name')))
      getData()
    }
	},[])

  /**
   * @author: wangyanhui
   * @description: 详情页面请求接口
   * @return {*}
   */  
  async function getData(){
    let detaileParams = {
      pool_meeting_id:meetid
    }
    let detaileData = await getMeetingDetaile(detaileParams)
    if(detaileData['status'] != 0){
      // Toast.show({
      //   icon: 'fail',
      //   content: data['message']
      // })
      console.log(detaileData)
      return;
    }
    let meettitle;
    if(detaileData['status'] == 0 &&
      detaileData['pool_resp'] && 
      detaileData['pool_resp']['content'] &&
      detaileData['pool_resp']['content']['mt_meeting_info'] && 
      detaileData['pool_resp']['content']['mt_meeting_info']['data'] && 
      detaileData['pool_resp']['content']['mt_meeting_info']['data'].length !=0 
    ){
      let meetingsDetaile = detaileData['pool_resp']['content']['mt_meeting_info']['data'][0]
      meettitle = meetingsDetaile.meettitle;
      setMingDetaile({
        mingType:meetingsDetaile.meettype,
        platform_meeting_id:meetingsDetaile.id,
        mingTypeAndCode:{
          meettitle:meetingsDetaile.meettitle,
          code:meetingsDetaile.code
        },
        time:{
          startTime:setMeetingTimeFun(meetingsDetaile.starttime),
          endTime:setMeetingTimeFun(meetingsDetaile.endtime),
          status:meetingsDetaile.status,
          state:setStatus(meetingsDetaile.status,meetingsDetaile.starttime,meetingsDetaile.endtime),
          originalStartTime:meetingsDetaile.starttime,
          duration:meetingsDetaile.duration
        },
        joinurl:meetingsDetaile.joinurl,
        starttime:meetingsDetaile.starttime,
        endtime:meetingsDetaile.endtime,
        pass:meetingsDetaile.pass
      })
    }

    let qcodeParams = {
      muid:meetid,
      host:localStorage.get('openid'),
      action:'SHARE_MEETING'
    }
    let qcodeData = await fetchQcode(qcodeParams);
    let {qcode_url} = qcodeData;
    qcode_url && setQcode_url(qcode_url)
    console.log(qcodeData)

    let EmailParams = {
      subject:`邀请您参加${meettitle}`,
      meeting_nm:meettitle,
      qcode_url:qcode_url
    }
    let emailData = await fetchEmailBody(EmailParams)
    setEmailData(emailData)
  }

  /**
   * @author: wangyanhui
   * @description: 前端重写会议状态
   * @param {status} 会议原始状态
   * @param {starttime} 会议开始时间
   * @param {endtime} 会议结束时间
   */
  function setStatus(status,starttime,endtime){
    if(status == '已取消'){
      return '已取消'
    }

    let statusStr = ''
    if(moment() < moment(starttime)){
      statusStr = '未开始'
    }else if(moment() >= moment(starttime) && moment() <= moment(endtime)){
      statusStr = '进行中'
    }else{
      statusStr = '已结束'
    }
    return statusStr;
  }

  function setMeetingTimeFun(time){
    let {year,month,date,hour,minute} = getCurrDate(moment(time));
    let hm =`${hour}:${minute}`
    let ymd =`${year}年${month}月${date}日`
    return {
      hm,
      ymd
    }
  }

  /**
   * @author: wangyanhui
   * @description: 显示隐藏弹窗
   * @param {*} dialogVisible
   * @param {*} ev
   * @return {*}
   */  
  function cloneDialog(dialogVisible,ev){
    setDialogVisible(dialogVisible)
  }

  function toToCurrentMeeting(){
    props.history.replace('/layout/createMeeting',{meetid})
  }

  function cancelMeeting(){
    Dialog.confirm({
      content: '是否取消会议',
      
      cancelText:<div>否</div>,
      confirmText:<div>是</div>,
      onConfirm: () => {
        let params = {
          pool_meeting_id:meetid,
          platform_meeting_id:mingDetaile.platform_meeting_id,
          meeting_type:mingDetaile.mingType
        }
        console.log(params,'paramsparamsparams')
        deleteMeeting(params).then((res)=>{
          let {message,status} = res;
          if(status == '0'){
            Toast.show({
              icon:'success',
              content: message,
            }) 
            setTimeout(()=>{
              weixinJSBridge('closeWindow')
            },1000)
          }else{
            // Toast.show({
            //   icon:'fail',
            //   content: message,
            // })
            console.log(message) 
          }
        })
      },
      onCancel:()=>{
      }
    })
  }

  /**
   * @author: wangyanhui
   * @description: 加入会议点击事件
   * @param {*} e
   * @return {*}
   */
  function joinTheMeeting(e){
    e.stopPropagation && e.stopPropagation();
    e.nativeEvent && e.nativeEvent.stopImmediatePropagation && e.nativeEvent.stopImmediatePropagation();
    mingDetaile.joinurl && window.location.replace(mingDetaile.joinurl)
  }


  /**
   * @author: wangyanhui
   * @description: 去分享页面
   * @param {*} e
   * @return {*}
   */
  function shareMeeting(e){
    
  }

  function locationReplace(url){
    if(window.history.replaceState){
      window.history.replaceState(null, document.title, url);
      window.history.go(0);
    }else{
      window.location.replace(url);
    }
  }

  function showGoToMeeting({state,originalStartTime}){
    let classStr = 'hidden ';
    let minuteDifference = (moment(originalStartTime) - moment()) / 1000 / 60; //当前时间和会议开始时间相差的分钟数
    if(state == '进行中' || (state == '未开始' && minuteDifference<=15)){
        //如果是进行中的会议 或者是 未开始的会议并且离开会前只剩15分钟以内显示加入会议按钮
        classStr += 'show'
    }
    return classStr;
  }


  return (
    <div className={styles.root + ' base_height'}>
      <div className="header">
        {/* <span className='header_left'>
          <Sphere theme="outline" size="15" fill="#333"/>
          <span className='left_label'>简体中文</span>
          <span className='left_triangle'></span>
          <span className='left_select'>
              <span>简体中文</span>
              <span>English</span>
            </span>
        </span>
        <span className='header_cent'>
          <DifferenceSet theme="outline" size="20" fill="#ccc" strokeWidth={2}/>
          <span className='cent_title'>腾讯会议</span>
        </span>
        <span className='header_right'>
          <img src='https://p.qqan.com/up/2022-6/16541413507702589.jpg' alt=''></img>
        </span> */}
      </div>
      <div className="body">
        {/* <TwoDimensionalCodeOne onClick={cloneDialog.bind(null,true)} theme="filled" size="24" fill="#2F88FF" strokeWidth={2}/> */}
        <ArrowLeft 
        className={`hidden ${!isBeShared && ' show'}`}
        onClick={()=>{
          if(source == 'currentList'){
            props.history.replace('/layout/currentList')
          }else if(source == 'historicList'){
            props.history.replace('/layout/historicList')
          }
        }} theme="filled" size="30" fill="#2e88ff" strokeWidth={3}/>
        <TwoDimensionalCodeOne
          onClick={cloneDialog.bind(null,true)}
          // onClick={(e)=>{shareMeeting(e)}}
          theme="filled" size="24" fill="#2F88FF" strokeWidth={2}
          className={`hidden ${(mingDetaile.time.state == '未开始' || mingDetaile.time.state == '进行中') && !isBeShared && ' show'}`}
        />
        <div className='body_top'>
          <span className='header_cent'>
            <DifferenceSet theme="filled" size="28" fill="#2F88FF" strokeWidth={2}/>
            <span className='cent_title'>{mingDetaile.mingType}</span>
          </span>
          <TheMeetingNumber mingDetaile={mingDetaile} mingTypeAndCode={mingDetaile.mingTypeAndCode}/>
          <TheMeetingTime time={mingDetaile.time}/>
        </div>
        <TheMeetingSplitLine background={'#edeef0'}/>
        <div className='body_bottom'>
          {/* <span>已添加我的会议</span> */}
          <CloseOne 
            onClick={cancelMeeting} 
            theme="filled" 
            size="36" 
            fill="#2e88ff" 
            strokeWidth={2}
            className={`hidden ${mingDetaile.time.state == '未开始' && !isBeShared && ' show'}`}
          />
          {/* <CloseOne onClick={cloneDialog.bind(null,true)} theme="filled" size="36" fill="rgba(95, 95, 95, 0.37)" strokeWidth={2}/> */}
          {/* <CloseOne theme="filled" size="31" fill="#2e88ff" strokeWidth={2}/> */}
        </div>
      </div>
      <div className="foot">
          <div onClick={toToCurrentMeeting} className={`hidden ${mingDetaile.time.state == '未开始' && !isBeShared &&' show'}`}>修改会议</div>
          {/* <div onClick={cancelMeeting}>取消会议</div> */}
          {/* <div>分享会议</div> */}
          <div onClick={(e)=>{joinTheMeeting(e)}} className={showGoToMeeting(mingDetaile.time)}>加入会议</div>
      </div>
      <div className={`dialog ${dialogVisible && 'showDialog'}`}>
        <div className='dialog_box'>
        <CloseOne onClick={cloneDialog.bind(null,false)} theme="outline" size="36" fill="#fff" strokeWidth={2}/>
          <div>
            <div className='dialog_box_body'>
              {/* <TheMeetingNumber />
              <TheMeetingTime /> */}
              <TheMeetingSplitLine background={'#edeef0'}/>
              <div className='box_body_qr_code'>
                <img src={qcode_url} alt='' ></img>
                {/* <span>请使用手机端腾讯会议APP扫码入会</span> */}
              </div>
            </div>  
            <div className='dialog_box_bottom'>
              <div onClick={()=>{setShowSharedBox(true)}}>
                <Devices theme="outline" size="20" fill="#fff"/>
                <span>微信分享</span>
              </div>
              <div onClick={()=>{
                if(emailData){
                  window.location.href = emailData
                }
              }}>邮件分享</div>
              {/* <div>
                <Devices theme="outline" size="20" fill="#fff"/>
                <span>邮件分享</span>
              </div> */}
            </div>  
          </div>
        </div>
      </div>
      <div onClick={()=>{setShowSharedBox(false)}} className={`shared_box ${showSharedBox && 'show_shared_box'}`}>
        <img src="https://oscimg.oschina.net/oscnet/fd2170a448e37826ae9f4d7088f287b8f24.jpg" />
      </div>
      <div className={`shared_box_two ${showSharedBoxTwo && 'show_shared_box_two'}`}>
        <img src={qcode_url} alt='' ></img>
        <span>请使用微信扫码关注公众号后扫码获取会议信息</span>
      </div>
    </div>
  );
}

export default MeetingDetaile;
