import http from '../../utils/http';

export const reservationMeeting = (data,config={})=>{
    return http('post','/h5/booking/meeting',data,config)
}

export const modifyMeeting = (data,config={})=>{
    return http('post','/h5/modify/meeting',data,config)
}

export const getMeetingDetaile = (params)=>{
    return http('get','h5/detail/meeting',{
        'params': params
    })
}
