

export function weixinJSBridge(eventName) {
    let ua = navigator.userAgent.toLowerCase();
    let isWx = false;
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
        //微信里面
        isWx = true;
    } else {
        isWx = false;
        console.log("不在微信里");
    }
    
    if(!isWx) return;
    if (typeof WeixinJSBridge === "undefined") {
        if (document.addEventListener) {
            document.addEventListener('WeixinJSBridgeReady', WeixinJSBridge.call(eventName), false);
        } else if (document.attachEvent) {
            document.attachEvent('WeixinJSBridgeReady', WeixinJSBridge.call(eventName));
            document.attachEvent('onWeixinJSBridgeReady', WeixinJSBridge.call(eventName));
        }
    } else {
        WeixinJSBridge.call(eventName)
    }
}

