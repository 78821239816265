import http from '../../utils/http';

export const fetchWxpUserInfo = (params)=>{
    return http('get','wx_pub/openid/get',{
        'params': params
    },{
        headers:{
            isLoading : false
        }
    })
}

export const refreshToken = (params)=>{
    return http('get','wx_pub/openid/refresh',{
        'params': params
    })
}

export const get_reg_auth_url = ()=>{
    return http('get',`wx_pub/get_reg_auth_url?uri=${window.location.href}`,{
        'params': {}
    })
}
