import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom'
import './index.scss';
import '@icon-park/react/styles/index.css'
import {RootStoreProvider,RootStore} from './store/index'
import App from './App';
import vConsole from './vconsole'

// 导入moment
import moment from 'moment';
// 导入中文语言包
import 'moment/locale/zh-cn';
// 设置中文
moment.locale('zh-cn');
// 关闭提示
moment.suppressDeprecationWarnings = true;

const rootStore = new RootStore()
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <RootStoreProvider store={rootStore}>
            <App />
        </RootStoreProvider>
    </BrowserRouter>
);
