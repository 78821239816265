import React,{useState,useEffect,useRef} from 'react';
import styles from './index.module.scss'
import { Collapse,Switch } from 'antd-mobile'
function CollapseComponent(props){
    const {title = '标题',checked=true, foucInput=()=>{},checkedFun=()=>{}} = props
    const [activeKey,setActiveKey] = useState('')
    useEffect(()=>{
        if(props.checked){
            setActiveKey('1') 
        }
    },[props.checked])

    useEffect(()=>{
        if(activeKey == '1'){
            foucInput()
        }
    },[activeKey])
    return (
        <div className={styles.root}>
            <Collapse
            accordion={true}
                activeKey={activeKey}
                arrow={()=>{
                    return <Switch
                                defaultChecked ={checked}
                                checked = {checked}
                                onChange = {(val)=>{
                                    checkedFun(val)
                                    val ? setActiveKey('1') : setActiveKey('')
                                }}
                            />
                }}
            >
                <Collapse.Panel forceRender={true} key='1' title={title}>
                    {
                        props.children || ''
                    }
                </Collapse.Panel>
            </Collapse>
        </div>
    )
}
export default CollapseComponent;