import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import localStorage from './localStorage'
import  Error  from '../page/error'
const renderRoutes = (routes) => (
  
  routes ? (
    <Switch>
      {routes.map((route, i) => (
        <Route
          key={route.path}
          path={route.path}
          exact={route.exact}
          // 1.4 render属性，回调函数插入子组件，props是匹配路由对象的方法
          render={(props) => {
            props = Object.assign({route:route,router:route}, props)
            let titleDom = document.querySelector('title')
            let titleText = props.route.title || '蓝鲸'
            titleDom && (titleDom.innerText = titleText)
            // if(localStorage.get('full_name')){
              return (
                route.render
                  ? route.render({...props})
                  : <route.component {...props}/>
              )
            // }else{
            //   return <Error />
            // }
          }}
        />
      ))}
    </Switch>
  ) : null
)
export default renderRoutes