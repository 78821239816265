import React,{useEffect} from 'react';
import styles from './index.module.scss';
import qs from 'querystring'
import localStorage from '../../utils/localStorage'
import {
  fetchQcode
} from './api.js'
function FollowPublicAccounts(props) {
  // 接收search参数
  const {search} = props.location || {}
  const {meetid} = qs.parse(search.slice(1))

  const [qcode_url,setQcode_url] = useState('')

  useEffect(()=>{
    getFetchQcode()
  },[])

  async function getFetchQcode(){
    let qcodeParams = {
      muid:meetid,
      host:localStorage.get('openid'),
      action:'SHARE_MEETING'
    }
    let qcodeData = await fetchQcode(qcodeParams);
    let {qcode_url} = qcodeData;
    qcode_url && setQcode_url(qcode_url)
    console.log(qcodeData)
  }
  return (
    <div className={styles.root}>
        <img src={qcode_url} alt='' ></img>
        <span>请使用微信扫码关注公众号后扫码获取会议信息</span>
    </div>
  );
}
export default FollowPublicAccounts;
