// Counter Store -> 管理 Counter 组件的 Store
import { makeAutoObservable } from "mobx"
class Authorization {
  // 数值状态
  code = ''
  state = ''
  constructor(rootStore) {
    // 将参数对象中的属性设置为 observable state
    // 将参数对象中的方法设置为 action
    makeAutoObservable(this, {}, {autoBind: true})
    this.rootStore = rootStore
  }

  saveCodeAndState(code,state) {
    this.code = code
    this.state = state
  }
}

export default Authorization
