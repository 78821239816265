import React from 'react';
import styles from './index.module.scss'
function TheMeetingSplitLine(props){
    return (
        <div className={styles.root}>
          <div style={{
            '--background':props.background
          }} className='body_center_the_divider'></div>
        </div>
    )
}
export default TheMeetingSplitLine;