import React,{useEffect} from 'react';
import styles from './index.module.scss'
import {WavesLeft} from '@icon-park/react'
import moment from 'moment';
function theMeetingListItem(props){
    const {item,mentingName,goToDetaile} = props;
    
    function setFontColor(state){
        switch(state){
            case '待开始':
            return 'yellow';
            case '进行中':
            return 'green';
            case '已取消':
            return 'gray';
        }
    }

    /**
     * @author: wangyanhui
     * @description: 加入会议点击事件
     * @param {*} e
     * @param {*} item
     * @return {*}
     */
     function joinTheMeeting(e,{joinurl}){
        e.stopPropagation && e.stopPropagation();
        e.nativeEvent && e.nativeEvent.stopImmediatePropagation && e.nativeEvent.stopImmediatePropagation();
        window.location.replace(joinurl)
    }

    function showGoToMeeting({state,starttime}){
        let classStr = '';
        let minuteDifference = (moment(starttime) - moment()) / 1000 / 60; //当前时间和会议开始时间相差的分钟数
        if(state == '进行中' || (state == '未开始' && minuteDifference<=15)){
            //如果是进行中的会议 或者是 未开始的会议并且离开会前只剩15分钟以内显示加入会议按钮
            classStr = 'show'
        }
        return classStr;
    }
    
    return (
        <div className={styles.root} onClick={goToDetaile && goToDetaile}>
            
            <div className='item_left' dangerouslySetInnerHTML={{__html:item.left}}></div>
            <div className={`item_right ${item.meettype == 'zoom' ? ' zoome_back' : ' teng_back'}`}>
            <p>
                <span>{item.theTheme}</span>
                <span className={setFontColor(item.state)}>
                    <WavesLeft theme="filled" size="13" fill="#32be32"/>{item.state}
                </span>
            </p>
            <p>
                <span>{item.time}</span>
            </p>
            <p>
                <span>发&nbsp;&nbsp;起&nbsp;&nbsp;人：{item.userName}</span>
                <span onClick={(e)=>{joinTheMeeting(e,item)}} className={showGoToMeeting(item)}>进入会议</span>
            </p>
            
            {
                item.hostkey && <p>
                    <span>主持人码：{item.hostkey}</span>
                </p>
            }
            {/* <p className={`${mentingName != '历史会议' && 'hidden'}`}> 
                <span>参会人：</span>
                <span>{item.participants}</span>
            </p> */}
            </div>
        </div>
    )
}
export default theMeetingListItem;