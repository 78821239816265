import moment from 'moment';

export function getCurrDate(date = new Date()){
  let year =moment(date).get('year');
  let month = moment(date).get('month')+1;
  let day = moment(date).get('date');
  let hour = moment(date).get('hour');
  let minute = moment(date).get('minute');
  let dateObj = {
    year: year,
    month: month,
    date: day,
    hour: hour < 10 ? '0'+ hour : hour,
    minute: minute < 10 ? '0'+ minute : minute,
    week:getWeekString(date),
    dateObj:new Date(`${year}/${month}/${day} ${hour}:${minute}`)
  }
  return dateObj;
}

export function getDate(date = new Date()){
  let year =moment(date).get('year');
  let month = moment(date).get('month')+1;
  let day = moment(date).get('date');
  let hour = moment(date).get('hour');
  let minute = moment(date).get('minute');
  let second = moment(date).get('second');
  let millisecond = moment(date).get('millisecond');
  let dateObj = {
    year: year,
    month: month,
    date: day,
    hour: hour < 10 ? '0'+ hour : hour,
    minute: minute < 10 ? '0'+ minute : minute,
    second: second,
    millisecond: millisecond,
    week:getWeekString(date),
    dateObj:new Date(date)
  }
  return dateObj;
}

export function getNewDate(currentDate){
  let dateArr = [];
  let date = currentDate || new Date();
  let min = currentDate ? moment(date).get('minutes') : date.getMinutes();
  let startFormatdate = moment(date).add(poorFun(min), 'minutes').format("YYYY/MM/DD HH:mm")
  let endFormatdate  = moment(startFormatdate).add(1, 'hours').format("YYYY/MM/DD HH:mm")

  dateArr.push(getDate(startFormatdate),getDate(endFormatdate),poorFun(Number(min)))
  return dateArr;
}

export function getDelayTheTime(date,num,type){
  let newDate = moment(date).add(num, type).format("YYYY/MM/DD HH:mm")
  return getDate(newDate);
}

export function getDelayMinutesDate(date,num){
  let b = {...date,month:date.month-1}
  let str = moment(b).add(num, 'minutes').format("YYYY/MM/DD HH:mm")
  return getDate(str);
}

export function poorFun(minutes){
  let arr = [0,5,10,15,20,25,30,35,40,45,50,55]
  let poor = 0;
  for(let i=0; i<arr.length; i++){
    if(arr[i]>=minutes){
      poor = arr[i] - minutes;
      break;
    }
    if(minutes > 55){
      poor = 60 - minutes;
      break;
    }
  }
  return poor;
}

// 获取传入时间和当前时间差几天
export function diffDays(date){
  let days = moment(date).diff(getCurrDays()[0],'days');
  return days;
}

// 获取当前传入日期是周几
export function getWeekString(date = new Date()){
  let week = moment(moment(date).format()).day();
  let stringWeek = ''
  switch(week){
    case 0:
      stringWeek = '周日'
      break;
    case 1:
      stringWeek = '周一'
      break;
    case 2:
      stringWeek = '周二'
      break;
    case 3:
      stringWeek = '周三'
      break;
    case 4:
      stringWeek = '周四'
      break;
    case 5:
      stringWeek = '周五'
      break;
    case 6:
      stringWeek = '周六'
      break;
  }
  return stringWeek;
}

// 获取当天开始结束时间
export function getCurrDays(){
    let date = []
    date.push(moment().format('YYYY/MM/DD'))
    date.push(moment().format('YYYY/MM/DD'))
    // 获取当天的开始结束时间，精确到时分秒
    // moment().startOf('days').format('YYYY/MM/DD HH:mm:ss')
    // moment().endOf('days').format('YYYY/MM/DD HH:mm:ss')
    return date
}
  
// 获取当前周的开始结束时间，周日到周六
export function getCurrWeekDays(){
  let date = []
  let start = moment().startOf('week').format('YYYY/MM/DD')
  let end = moment().endOf('week').format('YYYY/MM/DD')
  date.push(start)
  date.push(end)
  return date
}
// 获取上一周的开始结束时间，周日到周六
export function getLastWeekDays(){
  let date = []
  let start = moment().week(moment().week() - 1).startOf('week').format('YYYY/MM/DD')
  let end = moment().week(moment().week() - 1).endOf('week').format('YYYY/MM/DD')
  date.push(start)
  date.push(end)
  return date
}
// 获取下一周的开始结束时间，周日到周六
export function getNextWeekDays(){
  let date = []
  let start = moment().week(moment().week() + 1).startOf('week').format('YYYY/MM/DD')
  let end = moment().week(moment().week() + 1).endOf('week').format('YYYY/MM/DD')
  date.push(start)
  date.push(end)
  return date
}
// 获取当前月的开始结束时间
export function getCurrMonthDays(){
  let date = []
  let start = moment().startOf('month').format('YYYY/MM/DD')
  let end = moment().endOf('month').format('YYYY/MM/DD')
  date.push(start)
  date.push(end)
  return date
}
// 获取上一月的开始结束时间
export function getLastMonthDays(){
  let date = []
  let start = moment().month(moment().month() - 1).startOf('month').format('YYYY/MM/DD')
  let end = moment().month(moment().month() - 1).endOf('month').format('YYYY/MM/DD')
  date.push(start)
  date.push(end)
  return date
}
// 当前季度
export function getCurrQuarter(){
  let date = []
  let start = moment().startOf('quarter').format('YYYY/MM/DD')
  let end = moment().endOf('quarter').format('YYYY/MM/DD')
  date.push(start)
  date.push(end)
  return date
}
// 上个季度
export function getLastQuarter(){
  let date = []
  let start = moment().quarter(moment().quarter() - 1).startOf('quarter').format('YYYY/MM/DD')
  let end = moment().quarter(moment().quarter() - 1).endOf('quarter').format('YYYY/MM/DD')
  date.push(start)
  date.push(end)
  return date
}

  
