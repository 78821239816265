import React,{useState,useEffect,useRef} from 'react';
import {connect} from 'react-redux'
import styles from './index.module.scss';
// import {renderRoutes} from 'react-router-config';
import renderRoutes from '../../utils/render-routes';
import { TabBar } from 'antd-mobile'
import {Electrocardiogram,Plan,DocDetail} from '@icon-park/react'
import {
  AppOutline,
  MessageOutline,
  MessageFill,
  UnorderedListOutline,
  UserOutline,
} from 'antd-mobile-icons'
import {useRootStore} from '../../store/index'
import { observer } from "mobx-react-lite"
const tabs = [
  // {
  //   key: '/layout/manual',
  //   title: '手册',
  //   icon: <DocDetail theme="outline" size="24" fill="#999999" strokeWidth={3}/>
  // },
  
  {
    key: '/layout/createMeeting',
    title: '预约',
    icon: <Plan theme="outline" size="24" fill="#999999" strokeWidth={3}/>
  },
  {
    key: '/layout/currentList',
    title: '会议',
    icon: <UnorderedListOutline />,
  },
  {
    key: '/layout/report',
    title: '报表',
    icon: <Electrocardiogram theme="outline" size="24" fill="#999999" strokeWidth={3}/>,
  }
  // {
  //   key: '/layout/userCenter',
  //   title: '我的',
  //   icon: <UserOutline />,
  // },
]

function Layout(props) {
  let {environment} = useRootStore()

  const {route,history} = props;
  let {pathname} = history.location;
  if(pathname == '/layout/currentList' || pathname == '/layout/historicList'){
    pathname = '/layout/currentList'
  }

  const setRouteActive = (value) => {
    history.replace(value)
    let loading = document.getElementById('loading');
    if(loading && loading.className != 'hidden'){
      loading.className = 'hidden'
    }
    // loading && (loading.className = 'hidden');
  }


  return (
    <div className={styles.root}>
        {renderRoutes(route.routes)}
        <TabBar className={`${environment.softKeyboardState ? 'hidden_tab' : 'show_tab'}`} activeKey={pathname} onChange={value => setRouteActive(value)}>
          {tabs.map(item => (
            <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
          ))}
        </TabBar>
    </div>
  );
}
export default observer(Layout);
