import React from 'react';
import styles from './index.module.scss'
import {Copy} from '@icon-park/react'
import { Toast } from 'antd-mobile'
import localStorage from '../../utils/localStorage'
function TheMeetingNumber(props){
    const {mingTypeAndCode={
            meettitle:'',
            code:''
          },
          mingDetaile={
            platform_meeting_id:'',
            mingType:'',
            mingTypeAndCode:{
              meettitle:'',
              code:''
            },
            time:{
              startTime:{
                  hm:'',
                  ymd:''
              },
              endTime:{
                  hm:'',
                  ymd:''
              },
              status:'',
              originalStartTime:'',
              state:'',
              duration:''
            },
            joinurl:'',
            starttime:'',
            endtime:'',
            pass:''
          }
    } = props

    function setCodeFun(code){
        let arr = [];
        if(code){
            let str = ''
            for(let i=0; i<code.length; i++){
                str+=code[i]
                if(i!=0&&(i+1)%3==0){
                    str+=','
                }
            }
            arr = str.split(',')
        }
        return arr;
    }

    /**
     * @param {String} text 需要复制的内容
     * @return {Boolean} 复制成功:true或者复制失败:false  执行完函数后，按ctrl + v试试
     */
    function copyText(text){
        if(!text) return;
        var textareaC = document.createElement('textarea');
        textareaC.setAttribute('readonly', 'readonly'); //设置只读属性防止手机上弹出软键盘
        textareaC.value = text;
        document.body.appendChild(textareaC); //将textarea添加为body子元素
        textareaC.select();
        var res = document.execCommand('copy');
        document.body.removeChild(textareaC);//移除DOM元素
        console.log("复制成功");
        Toast.show({
            icon: 'success',
            content: '复制会议链接成功',
        })
        return res;
    }

    function copyFun(){
        let full_name = localStorage.get('full_name') || '';
        let meeting_type = (mingDetaile.mingType == 'zoom') ? "ZOOM" : (mingDetaile.mingType == 'tcmeeting') ? "TX" : "";
        let topic = mingDetaile.mingTypeAndCode.meettitle;
        let meeting_no = mingDetaile.mingTypeAndCode.code;
        let start_time = mingDetaile.starttime;
        let end_time = mingDetaile.endtime;
        let timezone = 8;
        let join_url = mingDetaile.joinurl;
        let pass = mingDetaile.pass;

        let str = genCopyText(full_name, meeting_type, topic, meeting_no, start_time, end_time, timezone, join_url, pass)
        copyText(str)
    }

    function genCopyText(full_name, meeting_type, topic, meeting_no, start_time, end_time, timezone, join_url, pass){
        let resultText = ""
        try{
          let startDateTime = start_time.split(' ')
          let endDateTime = end_time.split(' ')
          let meeting_datetime = genMeetingDateTime(startDateTime[0], endDateTime[0], startDateTime[1].substring(0, 5), endDateTime[1].substring(0, 5), timezone)
          resultText = `邀请您参加${meeting_type}会议 \n`
                      + `会议主题：${topic} \n`
                      + `会议时间：${meeting_datetime} \n`
                      + `\n`
                      + `点击链接直接加入会议：\n`
                      + `${join_url} \n`
                      + `\n`
                      + `${meeting_type}会议：${meeting_no} \n`
                      + `\n`
                      + `会议密码：${pass} \n`
                      + `\n`
                      + `复制该信息，打开手机${meeting_type}会议即可参与\n`
        }catch(e){
              console.log(e.stack);
        }finally{
          return resultText
        }
    }

    function genMeetingDateTime(start_date, end_date, start_time, end_time, timezone){
        let lEndDate = end_date + ' '
        if(start_date==end_date){
          lEndDate = ''
        }
  
        let timezoneString = ''
        if(timezone == 8){
          timezoneString =  `(GMT+08:00) 中国标准时间 - 北京`
        }else{
          timezoneString = `(GMT+${timezone.padStart(2, '0')}:00)`
        }
  
        let dateString = `${start_date} ${start_time}-${lEndDate}${end_time} ${timezoneString}`
        return dateString;
    }

    return (
        <div className={styles.root}>
            <div className='body_top_title'>{mingTypeAndCode.meettitle}</div>
            <div className='body_top_num_box'>
                <div className='body_top_num_div'>
                <ul className='body_top_num'>
                    {
                        setCodeFun(mingTypeAndCode.code).map((item,index)=>{
                            return <li key={index}>{item}</li>
                        })
                    }
                </ul>
                <Copy onClick={(e)=>{copyFun()}} theme="filled" size="18" fill="#2F88FF" strokeWidth={2}/>
                </div>
            </div>
        </div>
    )
}
export default TheMeetingNumber;