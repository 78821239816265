// Counter Store -> 管理 Counter 组件的 Store
import { makeAutoObservable } from "mobx"
class Environment {
  isWx = false

  // 软键盘展开收起默认状态
  softKeyboardState = false
 
  constructor(rootStore) {
    // 将参数对象中的属性设置为 observable state
    // 将参数对象中的方法设置为 action
    makeAutoObservable(this, {}, {autoBind: true})
    this.rootStore = rootStore
    this.setIsWx()
  }
	
  setIsWx() {
    let ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
        //微信里面
        this.isWx = true
    } else {
        this.isWx = false
        console.log("不在微信里");
    }
  }

  setSoftKeyboardState(state){
    this.softKeyboardState = state
  }
}

export default Environment
