import styles from './index.module.scss'
import React, { useState } from 'react'
import { Popup,CheckList } from 'antd-mobile'
function MeetingToRemindComponent(props){
  const {
    remindVisible=false,
    setMeetingVisible=()=>{},
    remindTheTime='',
    setRemindTheTime=()=>{},
  } = props

  const [checkListVaule, setCheckListVaule] = useState(props.remindTheTime)

    return (
        <div>
            <span
              onClick={() => {
                setMeetingVisible(true)
              }}
            >
              {remindTheTime}
            </span>
            <Popup
              visible={remindVisible}
              onMaskClick={() => {
                setMeetingVisible(false)
              }}
            >
              <div className={styles.root}>
                <CheckList defaultValue={[remindTheTime]} onChange={(value)=>{
                  setCheckListVaule(value)
                }}>
                  <div className='header'>
                    <span onClick={()=>{
                      setMeetingVisible(false)}
                    }>取消</span>
                    <span onClick={()=>{
                      setRemindTheTime(checkListVaule)
                      setMeetingVisible(false)
                    }
                    }>确定</span>
                  </div>
                  {/* <CheckList.Item value='无'>
                    无
                  </CheckList.Item>
                  <CheckList.Item value='会议开始时'>
                    会议开始时
                  </CheckList.Item> */}
                  <CheckList.Item value='5分钟前'>
                    5分钟前
                  </CheckList.Item>
                  <CheckList.Item value='15分钟前'>
                    15分钟前
                  </CheckList.Item>
                  <CheckList.Item value='1小时前'>
                    1小时前
                  </CheckList.Item>
                  {/* <CheckList.Item value='1天前'>
                    1天前
                  </CheckList.Item> */}
                </CheckList>
              </div>
            </Popup>
        </div>
    )
}
export default MeetingToRemindComponent;