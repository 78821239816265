import React from 'react';
import styles from './index.module.scss';
import {CityOne} from '@icon-park/react'
import localStorage from '../../utils/localStorage'
function UserCenter() {
  return (
    <div className={styles.root + ' base_height'}>
      <div className='header'>
        <div className='header_top'>
          用户信息
        </div>
        <div className='header_content'>
          <div className='content_left'>
            <span>
              <CityOne theme="outline" size="20" fill="#ffffff" strokeWidth={3}/>
            </span>
            <div>{localStorage.get('comp_nm') || ''}</div>
          </div>
          <div className='content_right'>
            <table className='header_table'>
              <tbody>
                <tr>
                  <td>姓名:</td>
                  <td>{localStorage.get('full_name') || ''}</td>
                </tr>
                <tr>
                  <td>邮箱:</td>
                  <td>{localStorage.get('email') || ''}</td>
                </tr>
                <tr>
                  <td>手机:</td>
                  <td>{localStorage.get('mobile') || ''}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className='body'>
        <h5 className='body_title'>购买正式版请联系</h5>
        <table className='body_table'>
          <tbody>
            <tr>
              <td>邮箱:</td>
              <td>biz@chunhon-info.com</td>
            </tr>
            <tr>
              <td>电话:</td>
              <td>13916110920</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className='bottom'>
        <a href="https://zoom.us/download#client_4meeting">ZOOM客户端下载</a>
        <a href="https://meeting.tencent.com/activities/">腾讯会议客户端下载</a>
      </div>
    </div>
  );
}
export default UserCenter;

