import React from 'react';
import styles from './index.module.scss';

function Manual() {
  return (
    <div className={styles.root}>
        <h5>蓝鲸在线会议资源池使用手册</h5>
        <p>1.如何注册试用</p>
        <h1>在微信搜索框输入“上海振瀚信息科技有限公司”，点击搜索并关注该公众号，点击[用户专区]，点击[试用申请]，进入注册页面，完成注册即可免费试用。</h1>
        <div className='img_box'>
          <span>
            <img src = {require('../../image/shiyong1.png')} alt="加载中" />
            <span>选择试用申请</span>
          </span>
          <span>
            <img src = {require('../../image/shiyong2.png')} alt="加载中" />
            <span>填写注册信息</span>
          </span>
        </div>
        <p>2.如何预约会议</p>
        <h1>点击公众号底部的[预约会议]菜单预约会议。</h1>
        <h1>编辑会议预约信息：</h1>
        <h1>会议主题：限制15个字符，如果不输入，默认显示预订人的会议，如：王五的会议。</h1>
        <h1>会议时间：会议的开始时间和结束时间，会议开始时间不能晚于结束时间。</h1>
        <h1>会议类型：zoom会议或者腾讯会议二选一。</h1>
        <h1>会议密码：默认开启状态，系统默认随机生成6位数字密码。支持用户自定义会议密码，仅支持设置6位数的数字密码。</h1>
        <h1>会议录制：默认关闭状态，开启之后默认启用本地录制。</h1>
        <h1>屏幕水印：默认关闭状态，不开启水印。当设置为开启状态时，进入会议有水印。</h1>
        <div className='img_box'>
          <span>
            <img src = {require('../../image/yuyue1.png')} alt="加载中" />
            <span>预约会议</span>
          </span>
          <span>
            <img src = {require('../../image/yuyue2.png')} alt="加载中" />
            <span>会议时间设置</span>
          </span>
        </div>
        <p>3.如何查看会议</p>
        <h1>多种查看会议方式：</h1>
        <h1>1.点击公众号底部[我的会议]菜单进入会议列表页面查看会议信息。</h1>
        <h1>2.进入公众号消息通知列表，查看会议信息。</h1>
        <div className='img_box'>
          <span>
            <img src = {require('../../image/chakan1.png')} alt="加载中" />
            <span>会议列表查看会议</span>
          </span>
          <span>
            <img src = {require('../../image/chakan2.png')} alt="加载中" />
            <span>消息列表查看会议</span>
          </span>
        </div>
        <p>4.如何分享会议</p>
        <h1>进入[会议列表]，选中某个会议，点击进入[会议详情]页面分享会议，支持微信分享和邮件分享。</h1>
        <div className='img_box'>
          <span>
            <img src = {require('../../image/fenxiang1.png')} alt="加载中" />
            <span>点击分享菜单</span>
          </span>
          <span>
            <img src = {require('../../image/fenxiang2.png')} alt="加载中" />
            <span>微信或邮件分享</span>
          </span>
        </div>
        <p>5.如何查看报表</p>
        <h1>系统提供3个图表展示资源池使用情况，分别为资源池区间统计图、资源池单日统计图、资源池使用率阈值趋势图，使我们的会议管理员能够实时掌握资源池账号的占用状态。</h1>
        <div className='img_box'>
          <span>
            <img src = {require('../../image/baobiao1.png')} alt="加载中" />
            <span>区间统计图和单日统计图</span>
          </span>
          <span>
            <img src = {require('../../image/baobiao2.png')} alt="加载中" />
            <span>使用率阈值趋势图</span>
          </span>
        </div>
    </div>
  );
}
export default Manual;
