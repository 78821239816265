import React,{useState,useEffect,Fragment} from 'react';
import styles from './index.module.scss'
import moment from 'moment';
import { PullToRefresh,DotLoading } from 'antd-mobile'
import {diffDays,getCurrDate} from '../../utils/time'
import TheMeetingListItem from '../../components/theMeetingListItem/index'
import {Down} from '@icon-park/react';
import {getListData} from './api'
import localStorage from '../../utils/localStorage'
import { Toast } from 'antd-mobile'

function MeetingList(props) {
  const [listData,setListData] = useState([])

  /**
   * @author: wangyanhui
   * @description: 页面第一次渲染完成
   * @return {*}
   */  
  useEffect(()=>{
    getData()
	},[])


  /**
   * @author: wangyanhui
   * @description: 处理列表日期函数
   * @param {Array} list
   * @return {Array} arr
   */  
  function processingDate(list){
    // {
    //   date:'2022/7/3',
    //   theTheme:'测试',
    //   time:'16:00-17:00',
    //   state:'待开始',
    //   userName:'汪延辉',
    //   index:'1',
    //   participants:'test'
    // },
    let arr = []
    let flg = 0;
    for(let i=0; i<list.length; i++){
      let item = list[i];
      arr.push({
          theTheme:item.meettitle,
          time:setMeetingTimeFun(item.starttime,item.endtime),
          state:setStatus(item.status,item.starttime,item.endtime),
          userName:localStorage.get('full_name'),
          index:i,
          participants:'',
          left:setLeftTitle(item.meetingdate), 
          meetid:item.meetid,
          joinurl: item.joinurl,
          status: item.status,
          starttime: item.starttime,
          endtime: item.endtime,
          meettype: item.meettype,
          hostkey: item.hostkey    
      })
    }

    for(let i=0; i<arr.length; i++){
      let itemLeft = arr[i].left;
      let flgLeft = arr[flg].left;
      if(i!=0 && itemLeft == flgLeft){
        arr[i].left = ''
      }else{
        flg = i
      }
    }
    return arr;
  }

    /**
   * @author: wangyanhui
   * @description: 前端重写会议状态
   * @param {status} 会议原始状态
   * @param {starttime} 会议开始时间
   * @param {endtime} 会议结束时间
   */
    function setStatus(status,starttime,endtime){
      if(status == '已取消'){
        return '已取消'
      }

      let statusStr = ''
      if(moment() < moment(starttime)){
        statusStr = '未开始'
      }else if(moment() >= moment(starttime) && moment() <= moment(endtime)){
        statusStr = '进行中'
      }else{
        statusStr = '已结束'
      }
      return statusStr;
    }

  function setLeftTitle(meetingdate){
    let title = ''
    let formatMeetingdate =moment(meetingdate).format('YYYY-MM-DD'); //日期格式化
    let diffDay = diffDays(formatMeetingdate);
    if(diffDay == -1){
      title = '昨天'
    }else{
      let month = moment(formatMeetingdate).get('month') + 1;
      let date = moment(formatMeetingdate).get('date');
      title = `<div class='month'>${month}月</div><div class='date'>${date}</div>`
    }
    return title;
  }

  function setMeetingTimeFun(starttime,endtime){
    let {hour:startHour,minute:startMinute} = getCurrDate(moment(starttime));
    let {hour:endHour,minute:endMinute} = getCurrDate(moment(endtime));
    let start =`${startHour}:${startMinute}`
    let end =`${endHour}:${endMinute}`
    return start + '-' + end;
  }

  /**
   * @author: wangyanhui
   * @description: 
   * @return {*}
   */  
  async function getData(){
    let params = {
      openid : localStorage.get('openid'),
      date_filter_type : 'past',
      query_date : moment().format("YYYY/MM/DD HH:mm"),
      order : 'desc',
    }
    let data = await getListData(params);
    if(data['status'] != 0){
      // Toast.show({
      //   icon: 'fail',
      //   content: data['message']
      // })
      console.log(data)
      return;
    }

    if(data['status'] == 0 &&
       data['pool_resp'] && 
       data['pool_resp']['content'] &&
       data['pool_resp']['content']['mt_meeting_info'] && 
       data['pool_resp']['content']['mt_meeting_info']['data']
    ){
      let meetingsList = data['pool_resp']['content']['mt_meeting_info']['data'];
      if(meetingsList.length != 0){
        setListData([...processingDate(meetingsList)])
      }else{
        setListData([])
      }
    }
  }


  function goToCurrentMeetingPage(){
    props.history.replace('/layout/currentList')
  }

  function goToDetaile({meetid,state}){
    if(state!='已取消'){
      props.history.replace(`/layout/meetingDetaile?openid=${localStorage.get('openid')}&source=historicList&meetid=${meetid}&full_name=${localStorage.get('full_name')}`)
    }else{
      Toast.show({
        content: '会议已取消',
        position: 'top'
      })
    }
  }

  const statusRecord = {
    pulling: '用力拉',
    canRelease: '松开吧',
    refreshing: <DotLoading color='primary' />,
    complete: '下拉可以刷新列表',
  }

  return (
    <div className={styles.root + ' base_height'}>
      <div className='app_header'>
        <span className='title' onClick={goToCurrentMeetingPage}>当前会议</span>
        <Down theme="outline" size="22" fill="#ccc"/>
      </div>
      <div className='app_content'>
        <PullToRefresh
          onRefresh={async () => {
            await getData()
          }}
          renderText={status => {
            return <div>{statusRecord[status]}</div>
          }}
        >
          {
            listData.length!= 0 ? listData.map((item)=>{
              return (
                <TheMeetingListItem
                  mentingName={'历史会议'}
                  state={item.state}
                  key={item.index} 
                  item={item}
                  goToDetaile={goToDetaile.bind(null,item)}
                >
                </TheMeetingListItem>
              )
            }) : <div className='temporarily_no_data'>暂无数据～</div>
          }
         </PullToRefresh>
      </div>
    </div>
  );
}

export default MeetingList;
