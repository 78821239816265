import React from 'react';
import {Redirect} from 'react-router-dom';
import CreateMeeting from '../page/createMeeting/index';
import CurrentList from '../page/currentList/index';
import HistoricList from '../page/historicList/index';
import MeetingDetaile from '../page/meetingDetaile/index';
import UserCenter from '../page/userCenter/index';
import Registered from '../page/registered/index';
import Layout from '../page/layout/index';
import Error from '../page/error/index';
import Manual from '../page/manual/index';
import Report from '../page/report/index';
import Authorization from '../page/authorization/index';
import FollowPublicAccounts from '../page/followPublicAccounts/index';

export default [
    {
        path:"/layout",
        component:Layout,
        routes:[
            {
                path:'/layout',
                exact: true,
                render:(props)=>{
                    return <Redirect to={'/layout/createMeeting'}></Redirect>
                },
                title:'预约会议'
            },
            {
                path: '/layout/report',
                component:Report,
                title:'预警面板'
            },
            {
                path: '/layout/createMeeting',
                component:CreateMeeting,
                title:'预约会议'
            },
            {
                path: '/layout/currentList',
                component:CurrentList,
                title:'会议列表'
            },
            {
                path: '/layout/historicList',
                component:HistoricList,
                title:'会议列表'
            },
            // {
            //     path: '/layout/userCenter',
            //     component:UserCenter,
            // },
            {
                path: '/layout/meetingDetaile',
                component:MeetingDetaile,
                title:'会议详情'
            }
        ]
    },
    {
        path:'/reactStatic/error',
        component:Error,
    },
    {
        path:'/reactStatic/authorization',
        component:Authorization,
    },
    {
        path:'/reactStatic/userCenter',
        component:UserCenter,
        title:'联系我们'
    },
    {
        path:'/reactStatic/registered',
        component:Registered,
        title:'上海振瀚信息科技有限公司'
    },
    {
        path:'/reactStatic/manual',
        component:Manual,
        title:'操作指南'
    },
    {
        path:'/reactStatic/followPublicAccounts',
        component:FollowPublicAccounts,
        title:''
    },
    {
        path:'/',
        // exact: true,
        render:()=>{
            return <Redirect to={'/layout'}></Redirect>
        },
        title:'预约会议'
    }
]