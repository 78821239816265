/**
 * @author: wangyanhui
 * @description: 生成随机的六位密码
 * @return {*}
 */
 export const randomNumberGeneration = ()=>{
    // 0-9的随机数
    let str = '';//容器
    for(let i =0; i<6; i++){//循环六次
      let num = Math.random()*9;//Math.random();每次生成(0-1)之间的数;
      num = parseInt(num,10);
      str+=num;
    }		
    return str;
  };


  /**
   * @author: wangyanhui
   * @description: 
   * @param {*} url
   * @return {*}
   */
  export const sliceURL = (url=window.location.href) => {
    if(url.indexOf('?')!==-1){
      let urlHost = url.slice(0,url.indexOf('?'));
      let urlarg =  url.slice(url.indexOf('?') + 1);
      let arr = urlarg.split('&').map(function (item, index, arr) {
          return item.split('=')
      })
      let params = {}
      arr.map(function (item, index, arr) {
          return params[item[0]] = item[1]
      })
      return [urlHost,params];
    }
    return false;
  }
