import React from 'react';
import styles from './index.module.scss'
function TheMeetingTime(props){
    const {time={
        startTime:{
            hm:'',
            ymd:''
        },
        endTime:{
            hm:'',
            ymd:''
        },
        status:'',
        state:'',
        originalStartTime:'',
        duration:''
    }} = props;
    return (
        <div className={styles.root}>
            <div className='top_time_box_left'>
                <p>{time.startTime.hm}</p>
                <p>{time.startTime.ymd}</p>
            </div>
            <div className='top_time_box_center'>
                <div>{time.state}</div>
                <div>
                    <span>{time.duration}分钟</span>
                </div>
                <div>(GMT+08:00)</div>
            </div>
            <div className='top_time_box_right'>
                <p>{time.endTime.hm}</p>
                <p>{time.endTime.ymd}</p>
            </div>
        </div>
    )
}
export default TheMeetingTime;