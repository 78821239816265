import http from '../../utils/http';

export const getMeetingDetaile = (params)=>{
    return http('get','h5/detail/meeting',{
        'params': params
    })
}

export const deleteMeeting = (params)=>{
    return http('delete','h5/cancel/meeting',{
        'params': params
    })
}

export const fetchQcode = (params)=>{
    return http('get','wx_pub/req_qcode',{
        'params': params
    })
}

export const fetchEmailBody = (params)=>{
    return http('get','h5/email/content',{
        'params': params
    })
}
