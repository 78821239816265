// Counter Store -> 管理 Counter 组件的 Store
import { createContext, useContext } from "react"
import Environment from './environment'
import Authorization from './authorization'
class RootStore {
  constructor() {
    this.environment = new Environment(this)
    this.authorization = new Authorization(this)
  }
}
const RootStoreContext = createContext()

const RootStoreProvider = ({ store, children }) => {
  return (
    <RootStoreContext.Provider value={store}>
      {children}
    </RootStoreContext.Provider>
  )
}

const useRootStore = () => {
  return useContext(RootStoreContext)
}

export {RootStore,RootStoreProvider,useRootStore}
