import React,{useLayoutEffect,useEffect,useState} from 'react';
import styles from './App.module.scss'
import route from './routes/index'
import {withRouter,Redirect,Route,Switch} from 'react-router-dom';
import renderRoutes from './utils/render-routes'
import {sliceURL} from './utils/tool';
import {useRootStore} from './store/index'
import { observer } from "mobx-react-lite"
import CreateMeeting from './page/createMeeting'
import Report from './page/report'



function App(props) {
  let {environment} = useRootStore()


  const [originalHeight, setOriginalHeight] = useState(()=>{
    return document.documentElement.clientHeight ||document.body.clientHeight;
  })

  useLayoutEffect(()=>{
    let urlArr = sliceURL(window.location.href);
    //如果url的问号后面有code 和 state两个字段就跳转至授权逻辑页面
    if(urlArr && urlArr[1] && urlArr[1].hasOwnProperty('code') && urlArr[1].hasOwnProperty('state')){
      props.history.replace('/reactStatic/authorization',{
        code:urlArr[1].code,
        state:urlArr[1].state,
        pathname:props.location.pathname
      })
    }
  },[])

  useEffect(()=>{
    if(isIos()){
      document.body.addEventListener('focusin', iptFocus)
      document.body.addEventListener('focusout', iptBlur)
    }else{
      window.addEventListener('resize',handleResize);
    }
  },[])

  function iptFocus(){
    //软键盘弹出的事件处理
      environment.setSoftKeyboardState(true)
      softKeyboardState()
  }

  function iptBlur(){
    //软键盘收起的事件处理
      environment.setSoftKeyboardState(false)
      softKeyboardState()
  }

  function handleResize(){
    //键盘弹起与隐藏都会引起窗口的高度发生变化
    let resizeHeight=document.documentElement.clientHeight || document.body.clientHeight;
    if(resizeHeight-0<originalHeight-0){
      //当软键盘弹起，在此处操作
      console.log('键盘弹起')
      environment.setSoftKeyboardState(true)
      softKeyboardState()
    }else{
      //当软键盘收起，在此处操作
      console.log('键盘收起')
      environment.setSoftKeyboardState(false)
      softKeyboardState()
    }
  }

  function softKeyboardState(){
    let base_height_dom = document.querySelector('.base_height') || null;
    if(base_height_dom && environment.softKeyboardState){
      base_height_dom.classList.add('height100')
    }else{
      base_height_dom.classList.remove('height100')
    }
  }

  function isIos(){
    let m = navigator.userAgent;
    let isIos = !!m.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    let result = isIos ? true : false;
    return result;
  }


  return (
    <div className={styles.root}>
      {renderRoutes(route)}
    </div>
  );
}
export default withRouter(observer(App));

