import React,{useState,useEffect} from 'react';
import styles from './index.module.scss';
import moment from 'moment';
import { Form, Input, Checkbox, Button, Toast } from 'antd-mobile'
import {weixinJSBridge} from '../../wx/weixinJSBridge'
import {registeredinterface} from './api'
import Cookies from 'js-cookie'
import localStorage from '../../utils/localStorage'
import {useRootStore} from '../../store/index'
// import { Input,Switch,Popup,CheckList } from 'antd-mobile'

function Registered() {
  //企业名称 value
  const [enterpriseName, setEnterpriseName] = useState('')

  //企业规模 value
  const [enterpriseSize, setEnterpriseSize] = useState('')

  //邮箱 value
  const [email, setEmail] = useState('')

  //姓名 value
  const [userName, setUserName] = useState('')

  //手机 value
  const [phone, setPhone] = useState('')

  //同意条款Checkbox初始状态
  const [checked, setChecked] = useState(false)

  //授权页面mobx state
  let {authorization} = useRootStore();

  useEffect(()=>{
    // document.querySelector()
    return () => {
      // clearCache();
    }
  },[])

  /**
   * @author: wangyanhui
   * @description: 清除cookie和localStorage里面的数据
   * @return {*}
   */
  function clearCache(){
    Cookies.remove('access_token')
    Cookies.remove('access_token_expires')
    Cookies.remove('refresh_token')
    localStorage.clear()
  }

  function submit(){
    if(isCheck()){//如果前端表单校验通过
      let access_token = Cookies.get('access_token');
      let openid = localStorage.get('openid');
      let nickname = localStorage.get('nickname');
      let full_name = localStorage.get('full_name');
      if(access_token == '' || openid == '' || nickname == ''){
        console.log(`关键数据openid，accessToken，nickname获取失败`)
      }
      
      if(full_name){
        Toast.show({
          icon: 'fail',
          content: '您已经申请过试用。',
        })
        setTimeout(()=>{
          weixinJSBridge('closeWindow')
        },1000)
        return;
      }
      let params = {
        openid,
        access_token,
        nickname,
        state:authorization.state,
        comp_nm:enterpriseName,
        comp_scale:enterpriseSize,
        email:email,
        full_name:userName,
        mobile:phone,
        days:30
      }
      console.log(params)
      registeredinterface(params).then((data)=>{
        if (data['status'] == 0) {
          Toast.show({
            icon: 'success',
            content: '你已经成功申请，请关注公众号，查看申请审核进度。',
          })
          setTimeout(()=>{
            weixinJSBridge('closeWindow')
          },1000)
        }else{
          Toast.show({
            icon: 'fail',
            content: '您的服务出现异常，请联系客服或稍后再试。',
          })
          console.log(`data body when error: \n ${data}`)
        }
      }).catch((err)=>{
        console.log(err)
      })
    }
  }

  function isCheck(){
    //如果企业名称为空 提示不能为空
    if(enterpriseName == ''){
      Toast.show({
        position:'top',
        content: '企业名称不能为空',
      })
      return false;
    }
    //如果企业名称小于4个字 提示企业名称最少输入4个字符
    if(enterpriseName.length < 4){
      Toast.show({
        position:'top',
        content: '企业名称最少输入4个字符',
      })
      return false;
    }
    //如果企业规模为空 提示不能为空
    if(enterpriseSize == ''){
      Toast.show({
        position:'top',
        content: '企业规模不能为空',
      })
      return false;
    }
    //如果邮箱为空 提示不能为空
    if(email == ''){
      Toast.show({
        position:'top',
        content: '邮箱不能为空',
      })
      return false;
    }
    //如果邮箱不是企业邮箱 提示输入企业邮箱
    if(/@163\.com/.test(email)||/@qq\.com/.test(email)){
      Toast.show({
        position:'top',
        content: '请输入企业邮箱。',
      })
      return false;
    }
    //如果邮箱无效 提示请输入有效的电子邮件地址
    if(!(/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/.test(email))){
      Toast.show({
        position:'top',
        content: '请输入有效的电子邮件地址',
      })
      return false;
    }
    //如果姓名为空 提示不能为空
    if(userName == ''){
      Toast.show({
        position:'top',
        content: '姓名不能为空',
      })
      return false;
    }
    //如果姓名为空 提示不能为空
    if(userName.length < 2){
      Toast.show({
        position:'top',
        content: '姓名最少输入两个字符',
      })
      return false;
    }
    //如果手机号码为空 提示手机号码不能为空
    if(phone == ''){
      Toast.show({
        position:'top',
        content: '手机号码不能为空',
      })
      return false;
    }
    //如果手机号码少于11位 提示请输入11位手机号码
    // if(phone.length < 11){
    //   Toast.show({
    //     position:'top',
    //     content: '请输入11位手机号码',
    //   })
    //   return false;
    // }
    if(!(/^1(0|1|2|3|4|5|6|7|8|9)\d{9}$/.test(phone))){
      Toast.show({
        position:'top',
        content: '请输入正确的手机号',
      })
      return false;
    }
    //如果没有勾线同意条款选项 提示请勾选我已阅读并同意服务条款、隐私政策。
    if(!checked){
      Toast.show({
        position:'top',
        content: '请勾选我已阅读并同意服务条款、隐私政策。',
      })
      return false;
    }

    //否则校验通过
    return true;
  }

  return (
    <div className={styles.root}>
      <div className='header_box'>
        <p>会议资源池免费试用申请</p>
        <p>(有效期1个月)</p>
      </div>
      <div className='content_box'>
        <Form layout='horizontal'>
          <Form.Item label='企业名称' name='enterpriseName'>
            <Input 
              value={enterpriseName}
              maxLength={20}
              minLength={4}
              onChange={(val)=>{setEnterpriseName(val)}}
              placeholder='请输入企业名称' clearable/>
          </Form.Item>
          <Form.Item label='企业规模' name='enterpriseSize'>
            <Input
              value={enterpriseSize}
              placeholder='请输入企业规模' 
              clearable 
              type='tel' 
              pattern='\d*'
              maxLength="5"
              onChange={(val)=>{setEnterpriseSize(val)}}
            />
          </Form.Item>
          <Form.Item label='邮箱' name='email'>
            <Input 
              value={email}
              onChange={(val)=>{setEmail(val)}}
              placeholder='请输入邮箱' 
              clearable 
            />
          </Form.Item>
          <Form.Item label='姓名' name='userName'>
            <Input 
              value={userName}
              onChange={(val)=>{setUserName(val)}}
              placeholder='请输入姓名' 
              clearable 
            />
          </Form.Item>
          <Form.Item label='手机' name='phone'>
            <Input
              value={phone}
              onChange={(val)=>{setPhone(val)}}
              placeholder='请输入手机' 
              clearable 
              type='tel' 
              pattern='\d*'
              maxLength="11"
            />
          </Form.Item>
          <Form.Item>
            <Checkbox
            checked={checked}
            defaultChecked={false}
            onChange={(val)=>{setChecked(val)}}
              style={{
                '--icon-size': '18px',
                '--font-size': '14px',
                '--gap': '6px',
              }}
            >
              {/* 我已阅读并同意<a>服务条款、隐私政策。</a> */}
            </Checkbox>
            <span className='terms'>我已阅读并同意<a href="policy.html">服务条款、隐私政策。</a></span>
          </Form.Item>
        </Form>
        <Button block color='primary' size='middle' onClick={submit}>
          立即申请
        </Button>
      </div>
    </div>
  );
}
export default Registered;

