import axios from "axios";
import ReactDOM from 'react-dom/client';
import { SpinLoading } from 'antd-mobile'
import {weixinJSBridge} from '../wx/weixinJSBridge'

 axios.defaults.timeout = 60000;
 axios.defaults.baseURL = '';
 switch(process.env.REACT_APP_ENV){
  case 'development':
    axios.defaults.baseURL = 'https://mmp.chunhon-info.com/flask';
  break;
  case 'production':
    axios.defaults.baseURL = '/flask';
  break;
  case 'sit':
    axios.defaults.baseURL = '/flask';
  break;
}

 // 当前正在请求的数量
let requestCount = 0

// 显示loading
function showLoading () {
    // if (requestCount === 0) {
      let loading = document.getElementById('loading');
      if(!loading){
        let dom = document.createElement('div')
        dom.setAttribute('id', 'loading')
        document.body.appendChild(dom);
        ReactDOM.createRoot(dom).render(<SpinLoading color='primary' />)
      }else{
        loading.className = ""
      }
    // }
    // requestCount++
}

// 隐藏loading
function hideLoading () {
    // requestCount--
    // if (requestCount === 0) {
      let loading = document.getElementById('loading');
      loading && (loading.className = 'hidden');
    // }
}

 /**
  * http request 拦截器
  */
 axios.interceptors.request.use(
   (config) => {
      if (config.headers.isLoading !== false) {
          showLoading()
      }
      config.data = JSON.stringify(config.data);
      config.headers = {
        "Content-Type": "application/json",
      };
      if(config.closeWindow){
        setTimeout(() => {
          weixinJSBridge('closeWindow')
        }, 1000);
      }
      return config;
   },
   (error) => {
      // 判断当前请求是否设置了不显示Loading
      if (error.config.headers.isLoading !== false) {
        hideLoading()
      }
      return Promise.reject(error);
   }
 );

 /**
  * http response 拦截器
  */
 axios.interceptors.response.use(
   (response) => {
      // 判断当前请求是否设置了不显示Loading
      if (response.config.headers.isLoading !== false) {
        hideLoading()
      }
      return response;
   },
   (error) => {
      if (error.config.headers.isLoading !== false) {
        hideLoading()
      }
      return Promise.reject(error);
   }
 );
 
 
 
 //统一接口处理，返回数据
 // eslint-disable-next-line import/no-anonymous-default-export
 export default function (fetch, url, data, config) {
   return new Promise((resolve, reject) => {
     switch (fetch) {
       case "get":
          axios.get(url, {...data,...config}).then((response) => {
              landing(response.config.url,response.config.params,response.data)
              resolve(response.data);
          })
          .catch((error) => {
              msg(error);
              reject(error);
          });
        
         break;
        case "delete":
          axios.delete(url, {...data,...config}).then((response) => {
              landing(response.config.url,response.config.params,response.data)
              resolve(response.data);
          })
          .catch((error) => {
              msg(error);
              reject(error);
          });
         break;
       case "post":
          axios.post(url,data, config).then((response) => {
              landing(response.config.url,response.config.params,response.data)
              resolve(response.data);
          })
          .catch((error) => {
              msg(error);
              reject(error);
          });
         break;
       default:
         break;
     }
   });
 }
 
 //失败提示
 function msg(err) {
   if (err && err.response) {
     switch (err.response.status) {
       case 400:
         console.log(err.response.data.error.details);
         break;
       case 401:
         console.log("未授权，请登录");
         break;
 
       case 403:
         console.log("拒绝访问");
         break;
 
       case 404:
        //  console.log("请求地址出错");
         break;
 
       case 408:
         console.log("请求超时");
         break;
 
       case 500:
         console.log("服务器内部错误");
         break;
 
       case 501:
         console.log("服务未实现");
         break;
 
       case 502:
         console.log("网关错误");
         break;
 
       case 503:
         console.log("服务不可用");
         break;
 
       case 504:
         console.log("网关超时");
         break;
 
       case 505:
         console.log("HTTP版本不受支持");
         break;
       default:
     }
   }
 }
 
 /**
  * 查看返回的数据
  * @param url
  * @param params
  * @param data
  */
 function landing(url, params, data) {
   if (data.code === -1) {
   }
 }
 
 