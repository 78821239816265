import React,{useState,useEffect,Fragment} from 'react';
import styles from './index.module.scss'
import moment from 'moment';
import {getListData} from './api'
import { PullToRefresh,DotLoading } from 'antd-mobile'
import {diffDays,getWeekString,getCurrDate} from '../../utils/time'
import TheMeetingListItem from '../../components/theMeetingListItem/index'
import {Down,More} from '@icon-park/react'
import localStorage from '../../utils/localStorage'
import { Toast } from 'antd-mobile'

function MeetingList(props) {
  const [listData,setListData] = useState([])

  /**
   * @author: wangyanhui
   * @description: 页面第一次渲染完成
   * @return {*}
   */  
  useEffect(()=>{
    //请求接口
    getData();
    console.log(123)
	},[])

  async function getData(){
    let params = {
      openid : localStorage.get('openid'),
      date_filter_type : 'active',
      query_date : moment().format("YYYY/MM/DD HH:mm"),
      order : 'asc',
    }
    let data = await getListData(params);
    if(data['status'] != 0){
      // Toast.show({
      //   icon: 'fail',
      //   content: data['message']
      // })
      console.log(data)
      return;
    }
    if(data['status'] == 0 &&
       data['pool_resp'] && 
       data['pool_resp']['content'] &&
       data['pool_resp']['content']['mt_meeting_info'] && 
       data['pool_resp']['content']['mt_meeting_info']['data']
    ){
      let meetingsList = data['pool_resp']['content']['mt_meeting_info']['data'];
      if(meetingsList.length != 0){
        setListData([...processingCurrentDate(meetingsList)])
      }else{
        setListData([])
      }
      
    }
  }


  /**
   * @author: wangyanhui
   * @description: 
   * @param {Array} list
   */  
  function processingCurrentDate(list){
    let flg = 0;
    let arr = [];
    for(let i=0; i<list.length; i++){
      let item = list[i];
      arr.push({
        theTheme: item.meettitle,                              //会议主题
        time : setMeetingTimeFun(item.starttime,item.endtime), //会议开始及结束时间
        userName :localStorage.get('full_name'),               //会议发起人
        index : i,
        left:setLeftTitle(item.meetingdate),                   //左侧的标题
        state:setStatus(item.status,item.starttime,item.endtime),                                     //会议的状态
        meetid: item.meetid,
        joinurl: item.joinurl,
        status: item.status,
        starttime: item.starttime,
        endtime: item.endtime,
        meettype: item.meettype,
        hostkey: item.hostkey          
      })
    }
    for(let i=0; i<arr.length; i++){
      let itemLeft = arr[i].left;
      let flgLeft = arr[flg].left;
      if(i!=0 && itemLeft == flgLeft){
        arr[i].left = ''
      }else{
        flg = i
      }
    }
    return arr;
  }

  function setLeftTitle(meetingdate){
      let title = ''
      let formatMeetingdate = moment(meetingdate).format('YYYY/MM/DD'); //日期格式化
      let diffDay = diffDays(formatMeetingdate);
      if(diffDay == 0){
        title = '今天'
      }else if(diffDay == 1){
        title = '明天'
      }else if(diffDay == 2){
        title = '后天'
      }else if(diffDay > 2 && diffDay < 7){
        title = getWeekString(formatMeetingdate)
      }else if(diffDay >= 7){
        let month = moment(formatMeetingdate).get('month') + 1;
        let date = moment(formatMeetingdate).get('date');
        title = `<div class='month'>${month}月</div><div class='date'>${date}</div>`
      }
      return title;
  }

  /**
   * @author: wangyanhui
   * @description: 前端重写会议状态
   * @param {status} 会议原始状态
   * @param {starttime} 会议开始时间
   * @param {endtime} 会议结束时间
   */
  function setStatus(status,starttime,endtime){
    if(status == '已取消'){
      return '已取消'
    }

    let statusStr = ''
    if(moment() < moment(starttime)){
      statusStr = '未开始'
    }else if(moment() >= moment(starttime) && moment() <= moment(endtime)){
      statusStr = '进行中'
    }else{
      statusStr = '已结束'
    }
    return statusStr;
  }


  function setMeetingTimeFun(starttime,endtime){
    let {hour:startHour,minute:startMinute} = getCurrDate(moment(starttime));
    let {hour:endHour,minute:endMinute} = getCurrDate(moment(endtime));
    let start =`${startHour}:${startMinute}`
    let end =`${endHour}:${endMinute}`
    return start + '-' + end;
  }

  function goToHistoricMeetingPage(){
    props.history.replace('/layout/historicList')
  }

  function goToDetaile({meetid,state}){
    if(state!='已取消'){
      props.history.replace(`/layout/meetingDetaile?openid=${localStorage.get('openid')}&source=currentList&meetid=${meetid}&full_name=${localStorage.get('full_name')}`)
      //push跳转+携带search参数
		// this.props.history.push(`/home/message/detail?id=${id}&title=${title}`)
    }else{
      Toast.show({
        content: '会议已取消',
        position: 'top'
      })
    }
  }

  const statusRecord = {
    pulling: '用力拉',
    canRelease: '松开吧',
    refreshing: <DotLoading color='primary' />,
    complete: '下拉可以刷新列表',
  }

  return (
    <div className={styles.root + ' base_height'}>
      <div className='app_header'>
        <span className='title' onClick={goToHistoricMeetingPage}>历史会议</span>
        <Down theme="outline" size="22" fill="#ccc"/>
      </div>
      <div className='app_content'>
        <PullToRefresh
          onRefresh={async () => {
            await getData()
          }}
          renderText={status => {
            return <div>{statusRecord[status]}</div>
          }}
        >
        {
          listData.length!= 0 ? listData.map((item)=>{
            return (
              <TheMeetingListItem
                mentingName={'当前会议'}
                key={item.index} 
                item={item}
                goToDetaile={goToDetaile.bind(null,item)}
              >
              </TheMeetingListItem>
            )
          }) : <div className='temporarily_no_data'>暂无数据～</div>
        }
        </PullToRefresh>
      </div>
    </div>
  );
}

export default MeetingList;
