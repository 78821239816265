import React,{useLayoutEffect} from 'react';
import styles from './index.module.scss';
import {fetchWxpUserInfo} from './api'
import Cookies from 'js-cookie'
import localStorage from '../../utils/localStorage'
import {useRootStore} from '../../store/index'

/**
 * @author: wangyanhui
 * @description: 获取n小时后的时间
 * @param {*} num
 * @return {*}
 */
function setExpiresTime(num){
    return new Date(new Date().getTime() + num * 60 * 60 * 1000);
}

/**
 * @author: wangyanhui
 * @description: 用户没有权限重定向到error页面
 * @param {*} props
 * @param {*} pathname
 * @return {*}
 */
 function redirect(props,pathname){
    if(localStorage.get('full_name') || pathname == '/reactStatic/registered' || pathname == '/reactStatic/manual' || pathname == '/reactStatic/userCenter' || pathname == '/reactStatic/followPublicAccounts'){
        props.history.replace(pathname)
    }else{
        clearCache() //清除缓存
        props.history.replace('/reactStatic/error')
    }
}

/**
 * @author: wangyanhui
 * @description: 清除cookie和localStorage里面的数据
 * @return {*}
 */
function clearCache(){
    Cookies.remove('access_token')
    localStorage.clear()
}


/**
 * @author: wangyanhui
 * @description: 获取url上的code和state字段调接口获取access_token
 * @param {*} props
 * @param {*} fulfilled
 * @param {*} rejected
 * @return {*}
 */
function set_access_token(props){
    const {code,state,pathname} = props.location.state || {}
    fetchWxpUserInfo({code,state}).then((res)=>{
        let {data,local_user,state} = res;
        if(state!='0'){
            console.log(res,'获取access_token接口200但是state!=0')
            redirect(props,pathname)
            return;
        }
        
        if(data && data.hasOwnProperty('access_token')){
            Cookies.set('access_token',data.access_token,{expires: setExpiresTime(2), path: '/' });
        }
        if(data && data.hasOwnProperty('openid')){
            localStorage.set('openid',data.openid)
        }
        if(local_user && local_user.hasOwnProperty('wx_nickname')){
            localStorage.set('nickname',local_user.wx_nickname)
        }
        if(local_user && local_user.hasOwnProperty('full_name')){
            localStorage.set('full_name',local_user.full_name)
        }
        if(local_user && local_user.hasOwnProperty('email')){
            localStorage.set('email',local_user.email)
        }
        if(local_user && local_user.hasOwnProperty('mobile')){
            localStorage.set('mobile',local_user.mobile)
        }
        if(local_user && local_user.hasOwnProperty('comp_nm')){
            localStorage.set('comp_nm',local_user.comp_nm)
        }

        //重定向
        redirect(props,pathname)
    }).catch((err)=>{
        console.info(err,'获取access_token非200或者报错')
        //重定向
        redirect(props,pathname)
    })
}

/**
 * @author: wangyanhui
 * @description: 
 * @param {*} props
 * @return {*}
 */
function init(props){
    const {pathname} = props.location.state || {}
    if(!Cookies.get('access_token') || !localStorage.get('full_name')){
        //获取不到用户信息先获取用户信息
        set_access_token(props)
    }else{
        //获取到用户信息后重定向
        redirect(props,pathname)
    }
}

function Authorization(props){
    //code,state 存储到mobx中
    const {code,state} = props.location.state || {}
    let {authorization} = useRootStore()
    authorization.saveCodeAndState(code,state)

    useLayoutEffect(()=>{
        init(props)
    },[]);
    return (
        <div className={styles.root}></div>
    )
}
export default Authorization;